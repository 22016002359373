
export function reviewsCarousel() {
    const $c = $('.reviews-carousel');

    if (!$c.length) return;

    $c.owlCarousel({
        items: 1,
        dots: false,
        nav: true,
        navText: ['<span class="if if-angle-left"></span>', '<span class="if if-angle-right"></span>'],
        mouseDrag: false,
        video: true,
        onChanged: () => {
            const youtubeVideo = $c.find('.youtube-video');
            if (youtubeVideo.length) {
                $c.find('.cover-wrapper').show();
                $c.find('.youtube-video').each(function () {
                    $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
                });
            }
        }
    });

}
