export function initVideo() {
  const $cw = $(".cover-wrapper");

  if ($cw.length) {
    $cw.on("click", function(e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).fadeOut(300);
      const youtubeVideo = $(this)
        .prev(".youtube-video")[0]
      if(youtubeVideo) {
        youtubeVideo.contentWindow.postMessage(
          '{"event":"command","func":"' + "playVideo" + '","args":""}',
          "*"
        );
      }
    });
  }
}
